import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingSpinnerService } from '../services/loading-spinner/loading-spinner.service';
import { Observable } from 'rxjs';

export const InterceptorSkipSpinner = 'X-Skip-Spinner';

@Injectable()
export class LoadingSpinnerInterceptor implements HttpInterceptor {
  constructor(private loadingModalService: LoadingSpinnerService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // ignore requests with the skip spinner header
    if (request.headers.has(InterceptorSkipSpinner)) {
      const headers = request.headers.delete(InterceptorSkipSpinner);
      return next.handle(request.clone({ headers }));
    }

    // ignore translation file requests
    if (
      request.url.includes('assets/i18n') ||
      request.url.includes('assets/quid-i18n') ||
      request.url.includes('assets/proquo-i18n') ||
      // REFACTOR: Use InterceptorSkipSpinner later. Might required sdk changes.
      request.url.includes('responseCount')
    ) {
      return next.handle(request);
    }

    const showWidget: string = request.headers.get('widget')
      ? request.headers.get('widget')
      : 'app';
    this.loadingModalService.showSpinner(showWidget);
    return next.handle(request).pipe(
      finalize(() => {
        const hideWidget = request.headers.get('widget')
          ? request.headers.get('widget')
          : 'app';
        this.loadingModalService.hideSpinner(hideWidget);
      })
    );
  }
}

export const LoadingSpinnerProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: LoadingSpinnerInterceptor,
  multi: true,
};
