/**
 * Generated by oas-generator
 * Do not modify
 */

import { Client } from './Client';
import {
  ConfigToken,
  defaultConfig,
  FullConfig,
  OperationConfig,
  SDKConfig,
} from './config';
import {
  addQuestionToPoll,
  AddQuestionToPollRequest,
  AddQuestionToPollResponse,
} from './operations/AddQuestionToPoll';
import {
  copyPoll,
  CopyPollRequest,
  CopyPollResponse,
} from './operations/CopyPoll';
import {
  copyPolls,
  CopyPollsRequest,
  CopyPollsResponse,
} from './operations/CopyPolls';
import {
  copyPollsFromSection,
  CopyPollsFromSectionRequest,
  CopyPollsFromSectionResponse,
} from './operations/CopyPollsFromSection';
import {
  createPoll,
  CreatePollRequest,
  CreatePollResponse,
} from './operations/CreatePoll';
import {
  createQuestion,
  CreateQuestionRequest,
  CreateQuestionResponse,
} from './operations/CreateQuestion';
import {
  deletePoll,
  DeletePollRequest,
  DeletePollResponse,
} from './operations/DeletePoll';
import {
  deletePolls,
  DeletePollsRequest,
  DeletePollsResponse,
} from './operations/DeletePolls';
import {
  deletePollsFromSection,
  DeletePollsFromSectionRequest,
  DeletePollsFromSectionResponse,
} from './operations/DeletePollsFromSection';
import {
  deleteQuestion,
  DeleteQuestionRequest,
  DeleteQuestionResponse,
} from './operations/DeleteQuestion';
import {
  detailedUserScores,
  DetailedUserScoresRequest,
  DetailedUserScoresResponse,
} from './operations/DetailedUserScores';
import { endPoll, EndPollRequest, EndPollResponse } from './operations/EndPoll';
import {
  getAverageScoreForContext,
  GetAverageScoreForContextRequest,
  GetAverageScoreForContextResponse,
} from './operations/GetAverageScoreForContext';
import {
  getMultipleQuestions,
  GetMultipleQuestionsRequest,
  GetMultipleQuestionsResponse,
} from './operations/GetMultipleQuestions';
import {
  getPollById,
  GetPollByIdRequest,
  GetPollByIdResponse,
} from './operations/GetPollById';
import {
  getPollInfoIfStarted,
  GetPollInfoIfStartedRequest,
  GetPollInfoIfStartedResponse,
} from './operations/GetPollInfoIfStarted';
import {
  getPolls,
  GetPollsRequest,
  GetPollsResponse,
} from './operations/GetPolls';
import {
  getQuestion,
  GetQuestionRequest,
  GetQuestionResponse,
} from './operations/GetQuestion';
import {
  getQuestionStatus,
  GetQuestionStatusRequest,
  GetQuestionStatusResponse,
} from './operations/GetQuestionStatus';
import {
  getResponseCountForPollQuestion,
  GetResponseCountForPollQuestionRequest,
  GetResponseCountForPollQuestionResponse,
} from './operations/GetResponseCountForPollQuestion';
import {
  getResultsForPollQuestion,
  GetResultsForPollQuestionRequest,
  GetResultsForPollQuestionResponse,
} from './operations/GetResultsForPollQuestion';
import {
  getUserInfo,
  GetUserInfoRequest,
  GetUserInfoResponse,
} from './operations/GetUserInfo';
import {
  getUserResponsesForPollQuestion,
  GetUserResponsesForPollQuestionRequest,
  GetUserResponsesForPollQuestionResponse,
} from './operations/GetUserResponsesForPollQuestion';
import {
  getUserScores,
  GetUserScoresRequest,
  GetUserScoresResponse,
} from './operations/GetUserScores';
import {
  getUserScoresForContext,
  GetUserScoresForContextRequest,
  GetUserScoresForContextResponse,
} from './operations/GetUserScoresForContext';
import {
  launchEngagementTool,
  LaunchEngagementToolResponse,
} from './operations/LaunchEngagementTool';
import {
  postUserPollScores,
  PostUserPollScoresRequest,
  PostUserPollScoresResponse,
} from './operations/PostUserPollScores';
import {
  reopenPoll,
  ReopenPollRequest,
  ReopenPollResponse,
} from './operations/ReopenPoll';
import {
  startPoll,
  StartPollRequest,
  StartPollResponse,
} from './operations/StartPoll';
import {
  submitPoll,
  SubmitPollRequest,
  SubmitPollResponse,
} from './operations/SubmitPoll';
import {
  updatePoll,
  UpdatePollRequest,
  UpdatePollResponse,
} from './operations/UpdatePoll';
import {
  updateQuestion,
  UpdateQuestionRequest,
  UpdateQuestionResponse,
} from './operations/UpdateQuestion';
import {
  updateQuestionStatusOfPollInstance,
  UpdateQuestionStatusOfPollInstanceRequest,
  UpdateQuestionStatusOfPollInstanceResponse,
} from './operations/UpdateQuestionStatusOfPollInstance';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class NetworkClient implements Client {
  private config: FullConfig;

  constructor(
    private http: HttpClient,
    @Inject(ConfigToken) moduleConfig: SDKConfig
  ) {
    this.config = { ...defaultConfig, ...moduleConfig };
  }

  getPollById(
    request: GetPollByIdRequest,
    config?: OperationConfig
  ): Observable<GetPollByIdResponse> {
    const mergedConfig = { ...this.config, ...config };
    return getPollById(this.http, request, mergedConfig);
  }

  updatePoll(
    request: UpdatePollRequest,
    config?: OperationConfig
  ): Observable<UpdatePollResponse> {
    const mergedConfig = { ...this.config, ...config };
    return updatePoll(this.http, request, mergedConfig);
  }

  deletePoll(
    request: DeletePollRequest,
    config?: OperationConfig
  ): Observable<DeletePollResponse> {
    const mergedConfig = { ...this.config, ...config };
    return deletePoll(this.http, request, mergedConfig);
  }

  addQuestionToPoll(
    request: AddQuestionToPollRequest,
    config?: OperationConfig
  ): Observable<AddQuestionToPollResponse> {
    const mergedConfig = { ...this.config, ...config };
    return addQuestionToPoll(this.http, request, mergedConfig);
  }

  startPoll(
    request: StartPollRequest,
    config?: OperationConfig
  ): Observable<StartPollResponse> {
    const mergedConfig = { ...this.config, ...config };
    return startPoll(this.http, request, mergedConfig);
  }

  endPoll(
    request: EndPollRequest,
    config?: OperationConfig
  ): Observable<EndPollResponse> {
    const mergedConfig = { ...this.config, ...config };
    return endPoll(this.http, request, mergedConfig);
  }

  getPolls(
    request: GetPollsRequest,
    config?: OperationConfig
  ): Observable<GetPollsResponse> {
    const mergedConfig = { ...this.config, ...config };
    return getPolls(this.http, request, mergedConfig);
  }

  createPoll(
    request: CreatePollRequest,
    config?: OperationConfig
  ): Observable<CreatePollResponse> {
    const mergedConfig = { ...this.config, ...config };
    return createPoll(this.http, request, mergedConfig);
  }

  deletePolls(
    request: DeletePollsRequest,
    config?: OperationConfig
  ): Observable<DeletePollsResponse> {
    const mergedConfig = { ...this.config, ...config };
    return deletePolls(this.http, request, mergedConfig);
  }

  submitPoll(
    request: SubmitPollRequest,
    config?: OperationConfig
  ): Observable<SubmitPollResponse> {
    const mergedConfig = { ...this.config, ...config };
    return submitPoll(this.http, request, mergedConfig);
  }

  reopenPoll(
    request: ReopenPollRequest,
    config?: OperationConfig
  ): Observable<ReopenPollResponse> {
    const mergedConfig = { ...this.config, ...config };
    return reopenPoll(this.http, request, mergedConfig);
  }

  launchEngagementTool(
    config?: OperationConfig
  ): Observable<LaunchEngagementToolResponse> {
    const mergedConfig = { ...this.config, ...config };
    return launchEngagementTool(this.http, mergedConfig);
  }

  createQuestion(
    request: CreateQuestionRequest,
    config?: OperationConfig
  ): Observable<CreateQuestionResponse> {
    const mergedConfig = { ...this.config, ...config };
    return createQuestion(this.http, request, mergedConfig);
  }

  getPollInfoIfStarted(
    request: GetPollInfoIfStartedRequest,
    config?: OperationConfig
  ): Observable<GetPollInfoIfStartedResponse> {
    const mergedConfig = { ...this.config, ...config };
    return getPollInfoIfStarted(this.http, request, mergedConfig);
  }

  updateQuestionStatusOfPollInstance(
    request: UpdateQuestionStatusOfPollInstanceRequest,
    config?: OperationConfig
  ): Observable<UpdateQuestionStatusOfPollInstanceResponse> {
    const mergedConfig = { ...this.config, ...config };
    return updateQuestionStatusOfPollInstance(this.http, request, mergedConfig);
  }

  getResultsForPollQuestion(
    request: GetResultsForPollQuestionRequest,
    config?: OperationConfig
  ): Observable<GetResultsForPollQuestionResponse> {
    const mergedConfig = { ...this.config, ...config };
    return getResultsForPollQuestion(this.http, request, mergedConfig);
  }

  getResponseCountForPollQuestion(
    request: GetResponseCountForPollQuestionRequest,
    config?: OperationConfig
  ): Observable<GetResponseCountForPollQuestionResponse> {
    const mergedConfig = { ...this.config, ...config };
    return getResponseCountForPollQuestion(this.http, request, mergedConfig);
  }

  getQuestionStatus(
    request: GetQuestionStatusRequest,
    config?: OperationConfig
  ): Observable<GetQuestionStatusResponse> {
    const mergedConfig = { ...this.config, ...config };
    return getQuestionStatus(this.http, request, mergedConfig);
  }

  getUserResponsesForPollQuestion(
    request: GetUserResponsesForPollQuestionRequest,
    config?: OperationConfig
  ): Observable<GetUserResponsesForPollQuestionResponse> {
    const mergedConfig = { ...this.config, ...config };
    return getUserResponsesForPollQuestion(this.http, request, mergedConfig);
  }

  getQuestion(
    request: GetQuestionRequest,
    config?: OperationConfig
  ): Observable<GetQuestionResponse> {
    const mergedConfig = { ...this.config, ...config };
    return getQuestion(this.http, request, mergedConfig);
  }

  getMultipleQuestions(
    request: GetMultipleQuestionsRequest,
    config?: OperationConfig
  ): Observable<GetMultipleQuestionsResponse> {
    const mergedConfig = { ...this.config, ...config };
    return getMultipleQuestions(this.http, request, mergedConfig);
  }

  updateQuestion(
    request: UpdateQuestionRequest,
    config?: OperationConfig
  ): Observable<UpdateQuestionResponse> {
    const mergedConfig = { ...this.config, ...config };
    return updateQuestion(this.http, request, mergedConfig);
  }

  deleteQuestion(
    request: DeleteQuestionRequest,
    config?: OperationConfig
  ): Observable<DeleteQuestionResponse> {
    const mergedConfig = { ...this.config, ...config };
    return deleteQuestion(this.http, request, mergedConfig);
  }

  copyPolls(
    request: CopyPollsRequest,
    config?: OperationConfig
  ): Observable<CopyPollsResponse> {
    const mergedConfig = { ...this.config, ...config };
    return copyPolls(this.http, request, mergedConfig);
  }

  copyPoll(
    request: CopyPollRequest,
    config?: OperationConfig
  ): Observable<CopyPollResponse> {
    const mergedConfig = { ...this.config, ...config };
    return copyPoll(this.http, request, mergedConfig);
  }

  getUserScores(
    request: GetUserScoresRequest,
    config?: OperationConfig
  ): Observable<GetUserScoresResponse> {
    const mergedConfig = { ...this.config, ...config };
    return getUserScores(this.http, request, mergedConfig);
  }

  postUserPollScores(
    request: PostUserPollScoresRequest,
    config?: OperationConfig
  ): Observable<PostUserPollScoresResponse> {
    const mergedConfig = { ...this.config, ...config };
    return postUserPollScores(this.http, request, mergedConfig);
  }

  getUserScoresForContext(
    request: GetUserScoresForContextRequest,
    config?: OperationConfig
  ): Observable<GetUserScoresForContextResponse> {
    const mergedConfig = { ...this.config, ...config };
    return getUserScoresForContext(this.http, request, mergedConfig);
  }

  getAverageScoreForContext(
    request: GetAverageScoreForContextRequest,
    config?: OperationConfig
  ): Observable<GetAverageScoreForContextResponse> {
    const mergedConfig = { ...this.config, ...config };
    return getAverageScoreForContext(this.http, request, mergedConfig);
  }

  detailedUserScores(
    request: DetailedUserScoresRequest,
    config?: OperationConfig
  ): Observable<DetailedUserScoresResponse> {
    const mergedConfig = { ...this.config, ...config };
    return detailedUserScores(this.http, request, mergedConfig);
  }

  getUserInfo(
    request: GetUserInfoRequest,
    config?: OperationConfig
  ): Observable<GetUserInfoResponse> {
    const mergedConfig = { ...this.config, ...config };
    return getUserInfo(this.http, request, mergedConfig);
  }

  deletePollsFromSection(
    request: DeletePollsFromSectionRequest,
    config?: OperationConfig
  ): Observable<DeletePollsFromSectionResponse> {
    const mergedConfig = { ...this.config, ...config };
    return deletePollsFromSection(this.http, request, mergedConfig);
  }

  copyPollsFromSection(
    request: CopyPollsFromSectionRequest,
    config?: OperationConfig
  ): Observable<CopyPollsFromSectionResponse> {
    const mergedConfig = { ...this.config, ...config };
    return copyPollsFromSection(this.http, request, mergedConfig);
  }
}
