import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { HttpBackend, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { SDKModule } from 'engagement-sdk';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { appReducers } from './store/reducers/app.reducer';
import { QRCodeModule } from 'angularx-qrcode';
import { LoadingSpinnerProvider } from './shared/http-interceptor/loading-spinner.interceptor';
import { SharedModule } from './shared/shared.module';
import { TokenProvider } from './shared/http-interceptor/token.interceptor';
import { TestComponent } from './test/test.component';
import { TagManagerService } from './shared/services/tag-manager/tag-manager.service';
import { PendoService } from './shared/services/pendo/pendo.service';
import { default as packageInfo } from '../../package.json';


// see https://github.com/rbalet/ngx-translate-multi-http-loader#readme for more info
export function HttpLoaderFactory(_httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(_httpBackend, [
      './assets/proquo-i18n/',
      './assets/quid-i18n/',
      './assets/i18n/'
    ]
  );
}
@NgModule({ declarations: [AppComponent, TestComponent],
    exports: [TranslateModule],
    bootstrap: [AppComponent], imports: [SDKModule.forRoot(() => ({
            baseUrl: environment.respondUrl,
            timeoutInSeconds: 30,
            retryEnabled: false,
        }), []),
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        QRCodeModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpBackend],
            },
        }),
        StoreModule.forRoot(appReducers),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        SharedModule], providers: [
        LoadingSpinnerProvider,
        TokenProvider,
        TagManagerService,
        PendoService,
        { provide: Window, useValue: window },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
