//ng & libs
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { filter, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

// etool
import { AppState } from './store/state/app.state';
import { getAppSpinner } from './store/selectors/loading.selectors';
import { LanguageOption } from './enum/language.enum';
import { TagManagerService } from './shared/services/tag-manager/tag-manager.service';
import { default as packageInfo } from '../../package.json';
import { IdentityService } from './shared/services/identity/identity.service';
import { environment } from 'src/environments/environment';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
@Component({
  selector: 'etool-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  isSpinnerOpen$ = this.store.select(getAppSpinner);
  constructor(
    private translateService: TranslateService,
    private title: Title,
    public router: Router,
    private store: Store<AppState>,
    private cookieService: CookieService,
    private tagManagerService: TagManagerService,
    private identityService: IdentityService
  ) {
    this.tagManagerService.addTag();

    //new relic browser agent setup
    if (environment?.nrBrowser?.enabled) {
      new BrowserAgent(environment?.nrBrowser?.options);
    }
  }

  ngOnInit() {
    console.log(`Polling UI v${packageInfo.version}`);
    this.identityService.initUserInfo();
    const language =
      this.cookieService.get('RESPOND_LANGUAGE').toLowerCase() ||
      LanguageOption.English;
    this.translateService.setDefaultLang(LanguageOption.English);
    this.translateService.use(language);
    this.setPageTitle();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  setPageTitle() {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationEnd),
        switchMap((event) => {
          return this.translatePageTitleValue();
        })
      )
      .subscribe((title: string) => {
        if (title === '') {
          title = 'McGraw Hill';
        } else {
          title = title + ' - McGraw Hill';
        }
        this.title.setTitle(title);
      });
  }

  translatePageTitleValue(): Observable<string> {
    let route: ActivatedRoute = this.router.routerState.root;
    let routeTitle = '';
    while (route!.firstChild) {
      route = route.firstChild;
    }
    if (route.snapshot.data['pageTitleTranslationKey']) {
      routeTitle = route!.snapshot.data.pageTitleTranslationKey;
      return this.translateService
        .stream(routeTitle)
        .pipe(takeUntil(this.destroy$));
    } else {
      return of('');
    }
  }
}
