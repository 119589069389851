export const environment = {
  envName: 'qastg',
  production: true,
  respondUrl: 'https://engagetoolqastg.nonprod.mheducation.com',
  connectUrl: 'https://connectqastg.edu.nonprod.mheducation.com',
  connectClassicUrl: 'https://connectqastg.mheducation.com',
  heclrUrl: 'https://accounts-qastg.mheducation.com',
  studentUserApi: 'https://accounts-api-qastg.mheducation.com',
  tagManagerEnv: 'env-3',
  tagManagerAuth: 'JHrMg16C5JgvZobuJiA44w',
  enablePendo: true,
  nrBrowser: {
    enabled: false,
    options: {},
  },
};
